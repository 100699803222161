import { Rate, Select, Space } from 'antd'
import CarList from './carlist';
import { GetWord } from '../../../../../../../utils';
import moment from 'moment';

const Overview = ({ data }) => {


    const handleChange = (value: string) => {
    };

    return (
        <div className='userdetails-body-rightarea-overview'>
            <div className='userdetails-body-rightarea-overview-commentsratings'>
                <span className='userdetails-body-rightarea-overview-commentsratings-title'>
                    {GetWord('Yorumlar & Değerlendirmeler')}</span>
                <div className='userdetails-body-rightarea-overview-commentsratings-textfilter'>
                    <span className='userdetails-body-rightarea-overview-commentsratings-textfilter-text'>({data?.feedbackCount + data?.commentCount})</span>
                    <div className='userdetails-body-rightarea-overview-commentsratings-textfilter-filter'>
                        <Space wrap>
                            <Select
                                defaultValue="2"
                                // style={{ width: 120 }}
                                onChange={handleChange}
                                options={[
                                    { value: '1', label: 'Last' },
                                    { value: '2', label: 'Latest' },
                                ]}
                            />
                        </Space>
                    </div>
                </div>


                <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea'>
                    <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-profilearea'>
                        <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-profilearea-profile'>
                            <img className='userdetails-body-rightarea-overview-commentsratings-ratingarea-profilearea-profile-photo'
                                src={data?.image} alt='' />
                            <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-profilearea-profile-name'>{data?.name}</span>
                        </div>
                        <button className='userdetails-body-rightarea-overview-commentsratings-ratingarea-profilearea-moreicon'>
                            <i className='icon-More' />
                        </button>
                    </div>
                    {data && data?.userLastFeedback.map((feedback, i) => (
                        <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-average'>
                            <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-average-text'>{GetWord('Ortalama')}:</span>
                            <Rate disabled defaultValue={feedback?.generalStar} className="userdetails-body-rightarea-overview-commentsratings-ratingarea-rate-star" />
                            <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-average-date'>{moment(feedback?.creationDate).format('LL')}</span>
                        </div>
                    ))}
                    {data && data?.userLastFeedback.map((feedback, i) => (
                        <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings'>
                            <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-rate'>
                                <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-text'>{GetWord('Yakıt Kalitesi')}</span>
                                <Rate disabled defaultValue={feedback?.fuel} className="userdetails-body-rightarea-overview-commentsratings-ratingarea-rate-star" />
                            </div>
                            <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-rate'>
                                <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-text'>{GetWord('WC Temizliği')}</span>
                                <Rate disabled defaultValue={feedback?.toilet} className="userdetails-body-rightarea-overview-commentsratings-ratingarea-rate-star" />
                            </div>
                            <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-rate'>
                                <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-text'>{GetWord('Market Hizmeti')}</span>
                                <Rate disabled defaultValue={feedback?.supermarket} className="userdetails-body-rightarea-overview-commentsratings-ratingarea-rate-star" />
                            </div>
                            <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-rate'>
                                <span className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-text'>{GetWord('Personel')}</span>
                                <Rate disabled defaultValue={feedback?.staff} className="userdetails-body-rightarea-overview-commentsratings-ratingarea-rate-star" />
                            </div>
                        </div>
                    ))}

                    <div className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-location'>
                        <i className='icon-Location userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-location-icon' />
                        {data && data?.userLastFeedback.map((item, i) => (
                            <span key={i} className='userdetails-body-rightarea-overview-commentsratings-ratingarea-ratings-location-text'>
                                {item.petrol_stations.title}
                            </span>
                        ))}
                    </div>
                </div>

                <div className='userdetails-body-rightarea-overview-commentsratings-commentarea'>
                    <div className='userdetails-body-rightarea-overview-commentsratings-commentarea-profilearea'>
                        <div className='userdetails-body-rightarea-overview-commentsratings-commentarea-profilearea-profile'>
                            <img className='userdetails-body-rightarea-overview-commentsratings-commentarea-profilearea-profile-photo'
                                src={data?.image} alt='' />
                            <span className='userdetails-body-rightarea-overview-commentsratings-commentarea-profilearea-profile-name'>{data?.name}</span>
                        </div>
                        <button className='userdetails-body-rightarea-overview-commentsratings-commentarea-profilearea-moreicon'>
                            <i className='icon-More' />
                        </button>
                    </div>
                    {data && data?.userLastComment.map((lastComment, i) => (
                        <div className='userdetails-body-rightarea-overview-commentsratings-commentarea-average'>
                            {/* <span className='userdetails-body-rightarea-overview-commentsratings-commentarea-average-text'>{GetWord('Ortalama')}:</span>
                        <Rate disabled defaultValue={4} className="userdetails-body-rightarea-overview-commentsratings-ratingarea-rate-star" /> */}
                            <span className='userdetails-body-rightarea-overview-commentsratings-commentarea-average-date'>{moment(lastComment?.creationDate).format('LL')}</span>
                        </div>
                    ))}
                    {data && data?.userLastComment.map((lastComment, i) => (
                        <span className='userdetails-body-rightarea-overview-commentsratings-commentarea-comments-text'>
                            {lastComment?.comment}
                        </span>
                    ))}
                    <div className='userdetails-body-rightarea-overview-commentsratings-commentarea-comments-location'>
                        <i className='icon-Location userdetails-body-rightarea-overview-commentsratings-commentarea-comments-location-icon' />
                        {data && data?.userLastComment.map((item, i) => (
                            <span className='userdetails-body-rightarea-overview-commentsratings-commentarea-comments-location-text'>
                                {item.petrol_stations.title}
                            </span>
                        ))}
                    </div>
                </div>
                <div className='userdetails-body-rightarea-overview-commentsratings-more'>
                    <button className='userdetails-body-rightarea-overview-commentsratings-more-text'>
                        {GetWord('Daha Fazla Göster')}
                    </button>
                </div>
            </div>

            {/* Cars */}
            <div className='userdetails-body-rightarea-overview-cars'>
                <span className='userdetails-body-rightarea-overview-cars-title'>
                    {GetWord('Araçlar')}</span>
                <div className='userdetails-body-rightarea-overview-cars-textfilter'>
                    <span className='userdetails-body-rightarea-overview-cars-textfilter-text'>({data?.vehicleCount})</span>
                    <div className='userdetails-body-rightarea-overview-cars-textfilter-filter'>
                        <Space wrap>
                            <Select
                                defaultValue="1"
                                // style={{ width: 120 }}
                                onChange={handleChange}
                                options={[
                                    { value: '1', label: 'All' },
                                ]}
                            />
                        </Space>
                    </div>
                </div>
                <div className='userdetails-body-rightarea-overview-cars-carsarea'>
                    <CarList data={data} />
                </div>
            </div>
        </div >
    )
}

export default Overview