import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker, TimePicker, Radio, Button, Checkbox, InputNumber, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Service from '../../../../../../Service';

const { TextArea } = Input;

const NotificatioTimed = ({ setIsRefresh, setModal, selectedData }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [brands, setBrands] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [isUrlActive, setIsUrlActive] = useState(selectedData ? selectedData.navigate === 'browser' : true);
    const getBrands = useCallback(() => {
        const service = new Service();
        service.GeneralService.getBrands().then((res) => {
            if (res) {
                setBrands([
                    { value: -1, label: 'Tümü' }
                    , ...res.map((item) => ({ value: item.carBrandId, label: item.name }))
                ]);
            }
        })
    }, []);

    const getFuelTypes = useCallback(() => {
        const service = new Service();
        service.GeneralService.getFuelTypes().then((res) => {
            if (res) {
                setFuelTypes(res.map((item) => ({ value: item.fuelTypeId, label: item.name + ` ${item.value}` })));
            }
        })
    }, []);

    useEffect(() => {
        getFuelTypes()
        getBrands()
    }, [getBrands, getFuelTypes]);
    dayjs.extend(customParseFormat);

    const timeformat = 'HH:mm';
    const dateFormat = 'DD/MM/YYYY';
    const today = moment().format(dateFormat);
    const currentTime = moment().format(timeformat);

    const onFinish = useCallback((values) => {
        const service = new Service()
        const data = {
            title: values.title,
            body: values.body,
            image: values.image,
            url: values.url,
            navigate: values.navigate,
            dates: values.dates.map((item) => ({
                count: item.count,
                date: dayjs(item.date).format('YYYY-MM-DD') + ' ' + dayjs(item.time).format('HH:mm:ss'),
            })),
        }

        if (selectedData.id !== undefined) {
            data["date"] = data.dates[0].date;
            data["count"] = data.dates[0].count;
            service.NotificationService.updateNotificationTimed(data, selectedData.id).then((res) => {
                if (!res.error) {
                    setIsRefresh((prev) => !prev);
                    setModal((prev) => !prev);
                }
            });
        } else {

            service.NotificationService.postNotificationTimed(data).then((res) => {
                if (!res.error) {
                    setIsRefresh((prev) => !prev);
                    setModal((prev) => !prev);
                }
            });
        }

    }, [selectedData, setIsRefresh, setModal]);

    const handleNavigateChange = (value) => {
        setIsUrlActive(value === 'browser');
        if (value !== 'browser') {
            form.setFieldsValue({ url: '' });
        }
    };


    return (
        <div className='notification-modal-market'>
            <Form onFinish={onFinish} initialValues={selectedData ? { ...selectedData, navigate: 'browser', carBrandId: -1 } : {
                dates: [{ count: 1, date: dayjs(), time: dayjs() }],
                navigate: 'browser', carBrandId: -1
            }} >
                <div className='notification-modal-market-content'>
                    {/* <span className='notification-modal-market-content-title'>
                        {GetWord('İçerik')}
                    </span> */}
                    <div className='notification-modal-market-content-form'>
                        <div className='notification-modal-market-content-form-titleimage'>
                            <Form.Item className='notification-modal-market-content-input' name='title'>
                                <Input placeholder={GetWord('Başlık')} />
                            </Form.Item>
                            <Form.Item className='notification-modal-market-content-url'
                                name="image"
                                rules={[
                                    {
                                        required: isUrlActive,
                                    },
                                    {
                                        type: 'url',
                                        warningOnly: isUrlActive,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('Görsel URL')} />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-market-content-form-content'>
                            <Form.Item className='notification-modal-market-content-textarea' name='body'>
                                <TextArea rows={4}
                                    placeholder={GetWord('İçerik')}
                                    style={{
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Ekran Yönlendir')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name='navigate'>
                                <Select
                                    options={[
                                        { value: 'browser', label: GetWord('Tarayıcı') },
                                        { value: 'Notifications', label: GetWord('Bildirimler') },
                                        { value: 'FuelPrices', label: GetWord('Yakıt Fiyatları') },
                                    ]}
                                    onChange={handleNavigateChange}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('URL')}
                            </span>
                            <Form.Item className='notification-modal-public-url'
                                name="url"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('URL')} disabled={!isUrlActive} />
                            </Form.Item>
                        </div>

                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Araç Markası')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name="carBrandId">
                                <Select

                                    options={brands}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Tür')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'general'}
                                    options={[
                                        { value: 'general', label: GetWord('Genel') },
                                        { value: 'station', label: GetWord('İstasyon') },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        {/* <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                                                <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                                    {GetWord('Şehir')}
                                                </span>
                    
                                                <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                                    <Select
                                                        defaultValue={'1'}
                                                        options={[
                                                            { value: '1', label: 'Fuel' },
                                                            { value: '2', label: 'Diesel' },
                                                            { value: '3', label: 'LPG' },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </div> */}
                    </div>
                </div>


                <div className='notification-modal-campaign-datetime'>
                    <span className='notification-modal-campaign-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span>

                    <Form.List name="dates">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => {


                                    return (
                                        <Space className='notification-modal-campaign-datetime-space'
                                            key={field.key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align="baseline"
                                        >
                                            <div className='notification-modal-campaign-datetime-datetimearea'>
                                                <div className='notification-modal-campaign-datetime-datetimearea-personarea'>
                                                    <span className='notification-modal-campaign-datetime-datetimearea-personarea-title'>
                                                        {GetWord('Kişi Sayısı')}
                                                    </span>
                                                    <Form.Item name={[field.name, 'count']} className='notification-modal-campaign-datetime-datetimearea-personarea-input'>
                                                        <InputNumber placeholder={GetWord('1')} min={1} />
                                                    </Form.Item>
                                                </div>


                                                <div className='notification-modal-campaign-datetime-datetimearea-datearea'>
                                                    <span className='notification-modal-campaign-datetime-datetimearea-datearea-title'>
                                                        {GetWord('Tarih')}
                                                    </span>
                                                    <Form.Item name={[field.name, "date"]} className='notification-modal-campaign-datetime-datetimearea-datearea-select'>
                                                        <DatePicker
                                                            format={dateFormat}
                                                            placeholder={today}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className='notification-modal-campaign-datetime-datetimearea-timearea'>
                                                    <span className='notification-modal-campaign-datetime-datetimearea-timearea-title'>
                                                        {GetWord('Saat')}
                                                    </span>
                                                    <Form.Item name={[field.name, 'time']} className='notification-modal-campaign-datetime-datetimearea-timearea-select'>
                                                        <TimePicker
                                                            format={timeformat}
                                                            placeholder={currentTime}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                        </Space>
                                    )
                                })}
                                <Form.Item>
                                    <Button className='notification-modal-campaign-datetime-button'
                                        type="dashed"
                                        onClick={() => add()}
                                    >
                                        {t('Yeni Tarih Ekle')}<PlusOutlined />
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </div>

                <Form.Item className='notification-modal-campaign-content-sendbutton'>
                    <Button htmlType="submit" type="primary" className='notification-modal-campaign-content-sendbutton-style'>
                        <span className='notification-modal-campaign-content-sendbutton-text'>
                            {GetWord('Gönder')}</span>
                    </Button>
                </Form.Item>
            </Form >
        </div >
    );
};


export default NotificatioTimed