import React, { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SidebarItem from "./SideBarItem";
import LoadingComp from "../../../shared/loading/loading";
import './scss/sidebar.scss';
import { GetWord } from "../../../../utils";
import SideBarItemv2 from "./SideBarItemv2";
import { useLocale } from "antd/es/locale";
import { useLocation } from "react-router-dom";

const datas = [
  {
    id: 1,
    isHeader: true,
    title: GetWord('Genel'),
    checkList: []
  },

  {
    id: 11,
    isHeader: false,
    title: GetWord('Bildirimler'),
    icon: <i className="icon-Notification-Add" />,
    pathname: "/notification",
    checkList: ["/notification", '/schedulednotif'],
    submenu:
      [
        { id: 111, title: 'Genel Bildirimler', pathname: '/notification' },
        { id: 112, title: 'Zamanlı Bildirimler', pathname: '/schedulednotif' },
      ]
  },


  {
    id: 12,
    isHeader: false,
    title: GetWord('Kullanıcılar'),
    icon: <i className="icon-User" />,
    pathname: "/users",
    checkList: []

  },
  {
    id: 13,
    isHeader: false,
    title: GetWord('Firmalar'),
    icon: <i className="icon-Office" />,
    pathname: "/companies",
    checkList: ['/companies', '/stations', '/tyre', '/batteries'],
    submenu:
      [
        { id: 131, title: 'Firmalar', pathname: '/companies' },
        { id: 132, title: 'İstasyonlar', pathname: '/stations' },
        { id: 133, title: 'Lastikçiler', pathname: '/tyre' },
        { id: 134, title: 'Akücüler', pathname: '/batteries' }
      ]
  },
  {
    id: 14,
    isHeader: false,
    title: GetWord('Masraflar'),
    icon: <i className="icon-Wallet" />,
    pathname: "/expenses",
    checkList: []

  },
  {
    id: 15,
    isHeader: false,
    title: GetWord('Petros Puanı'),
    icon: <i className="icon-Gas-Station" />,
    pathname: "/petrospoint",
    checkList: []

  },


  {
    id: 2,
    isHeader: true,
    title: GetWord('Uygulama İçi'),
    checkList: []

  },
  {
    id: 21,
    isHeader: false,
    title: GetWord('Anasayfa'),
    icon: <i className="icon-Home" />,
    pathname: "/home",
    checkList: []

  },
  {
    id: 22,
    isHeader: false,
    title: GetWord('Hikayeler'),
    icon: <i className="icon-Story" />,
    pathname: "/stories",
    checkList: []

  },
  {
    id: 23,
    isHeader: false,
    title: GetWord('Market'),
    icon: <i className="icon-Store" />,
    pathname: "/market",
    checkList: []

  },
  {
    id: 24,
    isHeader: false,
    title: GetWord('Haftanın Araçları'),
    icon: <i className="icon-Car" />,
    pathname: "/weeklycar",
    checkList: []

  },



  // // {
  // //   id: 7,
  // //   isHeader: false,
  // //   title: "Grafikler",
  // //   icon: <riIcon.RiPieChartLine className="nav-icon" />,
  // //   pathname: "/graphics",
  // // },
  // {
  //   id: 8,
  //   isHeader: false,
  //   title: "detail",
  //   icon: <giIcon.GiHamburgerMenu className="nav-icon" />,
  //   pathname: "/detail",
  // },
  // // {
  // //   id: 9,
  // //   isHeader: false,
  // //   title: "Konum",
  // //   icon: <biIcon.BiMapAlt className="nav-icon" />,
  // //   pathname: "/location",
  // // },
  // {
  //   id: 10,
  //   isHeader: true,
  //   title: "Extras",
  // },
  // {
  //   id: 11,
  //   isHeader: false,
  //   title: "settings",
  //   icon: <riIcon.RiSettings2Line className="nav-icon" />,
  //   pathname: "/settings",
  // },
  // // {
  // //   id: 12,
  // //   isHeader: false,
  // //   title: "customerloyaltyindex",
  // //   icon: <riIcon.RiPieChartLine className="nav-icon" />,
  // //   pathname: "/customerloyaltyindex",
  // // },
];


const Sidebar = () => {
  const [loading, setLoading] = useState(false);
  const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const local = useLocation();

  const refMenus = useRef([]);
  const refItems = useRef([]);


  const { sideBtn } = useSelector((state) => state.site);

  useEffect(() => {
    setIsCompaniesOpen(false);
    setIsNotificationsOpen(false);
  }, [local.pathname]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Doğru menü referanslarını kontrol et
  //     refMenus.current.forEach((refMenu, index) => {
  //       // refMenu ve refItem dizilerinin geçerli olduğuna emin olun
  //       if (refMenu && refItems.current[index]) {
  //         if (!refMenu.contains(event.target) && !refItems.current[index].contains(event.target)) {
  //           if (index === 0) {
  //             setIsCompaniesOpen(false);
  //           } else {
  //             setIsNotificationsOpen(false);
  //           }
  //         }
  //       }
  //     });
  //   };

  //   document.getElementById("sidebar").addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     if (document.getElementById("sidebar")) {
  //       document.getElementById("sidebar").removeEventListener("mousedown", handleClickOutside);
  //     }
  //   };
  // }, []);



  if (loading) {
    return <LoadingComp />;
  }

  return (
    <div id="sidebar">
      <aside className={"sidebar " + (sideBtn ? "active" : "")}>
        <nav className="sidebar-nav">
          {datas.map((item, index) => {
            if (item.id === 13 || item.id === 11) {

              const isOpen = item.id === 13 ? isCompaniesOpen : isNotificationsOpen;
              const setOpen = item.id === 13 ? setIsCompaniesOpen : setIsNotificationsOpen;


              return (
                <div key={index} ref={(el) => refMenus.current[index] = el}>
                  <div className={"sidebar-nav-link " + (item?.checkList.includes(local.pathname) || isOpen ? "active" : "")} onClick={() => setOpen((prev) => !prev)}>
                    {item.icon}
                    <span className="sidebar-nav-title">{GetWord(item.title)}</span>
                    <div className="sidebar-nav-drop-container" style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}>
                      <i className="icon-Arrow-Right sidebar-nav-drop" />
                    </div>
                  </div>
                  {((isOpen || item?.checkList.includes(local.pathname)) && item.submenu) && (
                    <div className="sidebar-submenu">
                      {item.submenu.map((subItem, subIndex) => (
                        <SideBarItemv2
                          key={subIndex}
                          pathname={subItem.pathname}
                          title={subItem.title}
                          refItem={(el) => refItems.current[subIndex] = el}
                        />
                      ))}
                    </div>
                  )}
                </div>
              );
            }

            return (
              <SidebarItem
                pathname={item.pathname}
                icon={item.icon}
                title={item.title}
                isHeader={item.isHeader}
                key={index}
              />
            );
          })}
        </nav>
      </aside>
      <div className={"sidebar-copyright " + (sideBtn ? "active" : "")}>
        <span className="sidebar-copyright-text">Copyright © 2021 Petros</span>
        <span className="sidebar-copyright-text">All rights reserved.</span>
      </div>
    </div>
  );
};

export default memo(Sidebar);