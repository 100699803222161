import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker, TimePicker, Radio, Button, Checkbox, InputNumber, Space } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Service from '../../../../../../Service';
import { type } from '@testing-library/user-event/dist/type';

const { TextArea } = Input;

const NotificatioTest = ({ setIsRefresh, setModal, selectedData }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [isLimitActive, setIsLimitActive] = useState(false);
    const [isUrlActive, setIsUrlActive] = useState(selectedData ? selectedData.navigate === 'browser' : true);



    const getUserList = useCallback(() => {
        const service = new Service();
        service.AdminService.getUserList().then((res) => {
            if (res.users) {
                setUsers(res.users.map((item) => ({ value: item.uid, label: item.name })));
            }
        })
    }, []);

    useEffect(() => {
        getUserList()
    }, [getUserList]);
    dayjs.extend(customParseFormat);

    const onFinish = useCallback((values) => {
        const service = new Service()
        service.NotificationService.postNotificationTest(values).then((res) => {
            if (!res.error) {
                setIsRefresh((prev) => !prev);
                setModal((prev) => !prev);
            }
        });

    }, [setIsRefresh, setModal]);

    const handleTypeChange = (value) => {
        setIsLimitActive(value === 'normal');
        if (value !== 'normal') {
            form.setFieldsValue({ limit: '' });
        }
    };

    const handleNavigateChange = (value) => {
        setIsUrlActive(value === 'browser');
        if (value !== 'browser') {
            form.setFieldsValue({ url: '' });
        }
    };


    return (
        <div className='notification-modal-market'>
            <Form onFinish={onFinish} initialValues={selectedData ? { ...selectedData, type: 'test', carBrandId: -1 } : {
                dates: [{ count: 1, date: dayjs(), time: dayjs() }],
                navigate: 'browser', carBrandId: -1, type: 'test'
            }} >
                <div className='notification-modal-market-content'>
                    {/* <span className='notification-modal-market-content-title'>
                        {GetWord('İçerik')}
                    </span> */}
                    <div className='notification-modal-market-content-form'>
                        <div className='notification-modal-market-content-form-titleimage'>
                            <Form.Item className='notification-modal-market-content-input' name='title'>
                                <Input placeholder={GetWord('Başlık')} />
                            </Form.Item>
                            <Form.Item className='notification-modal-market-content-url'
                                name="image"
                                rules={[
                                    {
                                        required: isUrlActive,
                                    },
                                    {
                                        type: 'url',
                                        warningOnly: isUrlActive,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('Görsel URL')} />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-market-content-form-content'>
                            <Form.Item className='notification-modal-market-content-textarea' name='body'>
                                <TextArea rows={4}
                                    placeholder={GetWord('İçerik')}
                                    style={{
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Ekran Yönlendir')}
                            </span>

                            <Form.Item name="navigate" className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    options={[
                                        { value: 'browser', label: GetWord('Tarayıcı') },
                                        { value: 'Notifications', label: GetWord('Bildirimler') },
                                        { value: 'FuelPrices', label: GetWord('Yakıt Fiyatları') },
                                    ]}
                                    onChange={handleNavigateChange}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('URL')}
                            </span>
                            <Form.Item className='notification-modal-public-url'
                                name="url"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input disabled={!isUrlActive} placeholder={GetWord('URL')} />
                            </Form.Item>
                        </div>

                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Tür')}
                            </span>

                            <Form.Item name="type" className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    options={[
                                        { value: 'test', label: GetWord('Test') },
                                        { value: 'normal', label: GetWord('Normal') },
                                    ]}
                                    onChange={handleTypeChange}
                                />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Limit')}
                            </span>

                            <Form.Item name="limit" className='notification-modal-market-content-input'>
                                <Input placeholder={GetWord('Limit')} disabled={!isLimitActive} />
                            </Form.Item>
                        </div>
                    </div>
                </div>


                <div className='notification-modal-campaign-datetime'>
                    <span className='notification-modal-campaign-datetime-title'>
                        {GetWord('Kişiler')}
                    </span>
                </div>

                {
                    !isLimitActive && (
                        <Form.Item name="id" className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                            <Select
                                mode="multiple"
                                options={users}
                            />
                        </Form.Item>)
                }


                <Form.Item className='notification-modal-campaign-content-sendbutton'>
                    <Button htmlType="submit" type="primary" className='notification-modal-campaign-content-sendbutton-style'>
                        <span className='notification-modal-campaign-content-sendbutton-text'>
                            {GetWord('Gönder')}</span>
                    </Button>
                </Form.Item>
            </Form >
        </div >
    );
};


export default NotificatioTest