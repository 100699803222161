import React, { useCallback, useEffect, useState } from 'react'
import './expenses.scss'
import { GetWord } from "../../../../utils";
import { Space, Table, Pagination, Modal, Tag, Checkbox, Divider } from 'antd';
import Service from '../../../../Service';
import Viewexpenses from './components/viewexpenses';
import moment from 'moment';

const Expenses = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [openViewexpenses, setOpenViewexpenses] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [data, setData] = useState(null);
    const [selectedExpenses, setSelectedExpenses] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const getExpenseListData = useCallback(() => {
        const service = new Service();
        const offset = (currentPage - 1) * pageSize;
        service.AdminService.getExpenseList(`limit=${pageSize}&offset=${offset}`).then((res) => {
            const newData = res.expenses.map((item, index) => ({
                ...item,
                key: item.id || index,
            }));
            setData({ ...res, expenses: newData });
        });
    }, [currentPage]);

    useEffect(() => {
        getExpenseListData();
    }, [getExpenseListData, isRefresh]);

    const currentPageData = data?.expenses || [];

    const viewexpensesOpen = (data) => {
        setSelectedExpenses(data);
        setOpenViewexpenses(true);
    };

    const noCancel = () => {
        setOpenViewexpenses(false);
        setSelectedExpenses(null);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const toggleVerification = useCallback((currentData) => {
        const service = new Service();
        service.ExpensesService.patchVerified({ isVerified: !currentData.isVerified }, currentData.expenseId).then((res) => {
            setIsRefresh((prev) => !prev);

        });
    }, []);




    const columns = [
        {
            title: GetWord('FİYAT'),
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <div className='expenses-table-price'>
                    <span className='expenses-table-price-text'>{record.price} {record.currency}</span>
                </div>
            ),
        },
        {
            title: <div className='expenses-table-date-title'>{GetWord('TARİHİ')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, item) => (
                <div className='expenses-table-date'>
                    <span className='expenses-table-date-date'>{moment(date).format('LL')}</span>
                    <span className='expenses-table-date-time'>{moment(date).format('LT')}</span>
                </div>
            ),
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: GetWord('KULLANICI'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div className='expenses-table-users'>
                    {/* <div className='expenses-table-users-profilephoto'></div> */}
                    <span className='expenses-table-users-text'>{record.users.name}</span>
                </div>
            ),
        },
        {
            title: GetWord('FİŞ GÖRSELİ'),
            dataIndex: 'expenses',
            key: 'expenses',
            render: (title, item) =>
                <button className='expenses-table-expenses' onClick={() => viewexpensesOpen(item)}>
                    <i className='icon-Eye expenses-table-expenses-icon' />
                    <span className='expenses-table-expenses-text'>{GetWord('Görüntüle')}</span>
                </button>,
        },

        {
            title: GetWord('ONAY DURUMU'),
            key: 'isVerified',
            dataIndex: 'isVerified',
            render: (_, { isVerified }) => (
                <>
                    {(() => {
                        let stat = isVerified ? GetWord('ONAYLANMIŞ') : GetWord('BEKLİYOR');
                        let color = isVerified ? 'cyan' : 'yellow';

                        return (
                            <Tag color={color} key={stat}>
                                {stat}
                            </Tag>
                        );
                    })()}
                </>
            ),
        },


        {
            title: GetWord('PLAKA'),
            dataIndex: 'licensePlate',
            key: 'licensePlate',
            render: (text, record) => (
                <div className='expenses-table-plate'>
                    <span className='expenses-table-plate-text'>{record.licensePlate}</span>
                </div>
            ),
        },

        {
            title: GetWord('KAZANILAN PUAN'),
            dataIndex: 'earnedPoint',
            key: 'earnedPoint',
            render: (text, record) => (
                <div className='expenses-table-point'>
                    <span className='expenses-table-point-text'>{record.earnedPoint} P</span>
                </div>
            ),
        },

        {
            title: GetWord('ONAY DURUMU'),
            key: 'isVerified',
            dataIndex: 'isVerified',
            render: (_, record, index) => (
                <button
                    className={record.isVerified ? 'expenses-table-options-verified' : 'expenses-table-options-notverified'}
                    onClick={() => toggleVerification(record)}
                >
                    <span
                        className={record.isVerified ? 'expenses-table-options-verified-text' : 'expenses-table-options-notverified-text'}
                    >
                        {record.isVerified ? 'İptal Et' : 'Onayla'}
                    </span>
                </button>
            ),
        }

    ];



    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
        columnTitle: 'SEÇ',
        onSelect: (record, selected) => {
            if (selected) {
                setSelectedRowKeys([...selectedRowKeys, record.key]);
            } else {
                setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key));
            }
        },
    };

    const handleSelectAll = () => {
        if (selectedRowKeys.length === currentPageData.length) {
            setSelectedRowKeys([]);
        } else {
            const allKeys = currentPageData.map((item) => item.key);
            setSelectedRowKeys(allKeys);
        }
    };
    const handleConfirmSelected = () => {
        var currentDataId = [];
        const updatedData = data.expenses.map((expense) => {
            if (selectedRowKeys.includes(expense.key) && !expense.isVerified) {
                currentDataId.push(expense.expenseId);
                // return { ...expense, isVerified: true };
            }
            return expense;
        });

        if (currentDataId.length > 0) {
            const service = new Service();
            service.ExpensesService.patchVerifiedMultiple({ expenseIds: currentDataId }).then((res) => {
                setIsRefresh((prev) => !prev);
            });
        }


        // setData({
        //     ...data,
        //     expenses: updatedData,
        // });

        setSelectedRowKeys([]);
    };


    return (
        <div className='expenses'>
            <div className="expenses-header">
                <p className='expenses-header-title'>
                    {GetWord('Masraf Fişleri')}
                </p>


                <div className='expenses-header-buttonarea'>
                    <button
                        onClick={handleSelectAll}
                        className='expenses-header-buttonarea-allbutton'
                        style={{ opacity: selectedRowKeys.length === currentPageData.length ? 0.5 : 1 }}
                    >
                        <span className='expenses-header-buttonarea-allbutton-text'>Tümünü Seç</span>
                    </button>
                    <button
                        className='expenses-header-buttonarea-confirmselected'
                        style={{
                            backgroundColor: selectedRowKeys.length > 0 ? '#033677' : '',
                            color: selectedRowKeys.length > 0 ? 'rgba(3, 54, 119, 0.50)' : ''
                        }}
                        onClick={handleConfirmSelected}                     >
                        <span className='expenses-header-buttonarea-confirmselected-text'>Seçili Olanları Onayla</span>
                    </button>

                </div>
            </div>

            <div className='expenses-table'>
                <Divider />
                <Table columns={columns} dataSource={currentPageData} pagination={false}

                    rowSelection={rowSelection} />
            </div>

            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')}</span>
                <Pagination
                    className='expenses-pagination'
                    current={currentPage}
                    pageSize={pageSize}
                    total={data?.totalCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div>
            <Modal
                title={GetWord('Fiş')}
                open={openViewexpenses}
                className='expenses-viewexpenses'
                onCancel={noCancel}
                footer={null}
            >
                {selectedExpenses && <Viewexpenses data={selectedExpenses} />}
            </Modal>
        </div>
    )
}

export default Expenses;
