import React, { useCallback, useEffect, useState } from 'react'
import './schedulednotifications.scss'
import { Space, Table, Tag, Pagination, Modal, Image, } from 'antd';
import moment from 'moment';
import Deletemodal from '../modal/deletemodal';
import Notificationdetail from '../modal/components/notificationdetail';
import Service from '../../../../../Service';
import { GetWord } from '../../../../../utils';
import Notificationmodal from '../modal/notificationmodal';
import dayjs from 'dayjs';


const ScheduledNotifications = () => {
    const [isShowNewNotificationModalOpen, setShowNewNotificationModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isShowNotificationDetailModal, setShowNotificationDetailModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState('6');
    const pageSize = 10;
    const [scheduleNotifications, setScheduleNotifications] = useState();
    const [refresh, setIsRefresh] = useState(false);
    const [selectedID, setSelectedID] = useState();
    const [selectedData, setSelectedData] = useState(null);

    const currentPageData = scheduleNotifications?.notifications || [];

    const getScheduledNotifications = useCallback(() => {
        const service = new Service()
        service.NotificationService.getScheduledNotifications(`limit=${pageSize}&offset=${currentPage - 1}`).then((res) => {
            setScheduleNotifications(res)
        })
    }, [currentPage])

    useEffect(() => {
        getScheduledNotifications();
    }, [currentPage, getScheduledNotifications, refresh])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const showNewNotificationModal = (value) => {
        setShowNewNotificationModalOpen(true);
        setDeleteModalOpen(false);
        setShowNotificationDetailModal(false);
        setSelectedData(value ? {
            body: value.body,
            id: value.id,
            image: value.image,
            navigate: value.navigate,
            title: value.title,
            url: value.url,
            dates: [{
                count: value.count,
                date: dayjs(value.date),
                time: dayjs(value.date),
            }]
        } : null);
    };

    const showDeleteModal = (id) => {
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(true);
        setShowNotificationDetailModal(false);
        setSelectedID();
    }

    const showNotificationDetailModal = () => {
        setShowNotificationDetailModal(true)
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(false)
    }

    const handleOk = () => {
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(false)
        setShowNotificationDetailModal(false)
    };

    const handleCancel = () => {
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(false)
        setShowNotificationDetailModal(false)
    };

    const columns = [
        {
            title: GetWord('BİLDİRİM'),
            dataIndex: 'title',
            width: '50%',
            key: 'notification',
            render: (text, data) => <div className='schedulednotifications-table-notification'>
                <button className='schedulednotifications-table-notification-eye' onClick={setShowNotificationDetailModal}>
                    <i className='icon-Eye notification-table-notification-eye-icon' />
                </button >
                <div className='schedulednotifications-table-notification-textarea'>
                    <span className='schedulednotifications-table-notification-textarea-title'>{text}</span>
                    <span className='schedulednotifications-table-notification-textarea-body'>{data.body}</span>
                </div>
            </div >,
        },

        {
            title: GetWord('RESİM'),
            dataIndex: 'image',
            key: 'image',
            render: (data) => {
                return (


                    <div className='schedulednotifications-table-count'>
                        <Image
                            width={50}
                            height={50}
                            src={data}
                        />
                        {/* <span className='schedulednotifications-table-count-text'>{data} Kişi</span> */}
                    </div>
                );
            },
        },

        {
            title: GetWord('GÖNDERİLEN EKRAN'),
            dataIndex: 'navigate',
            key: 'navigate',
            render: (data) => {
                let displayText = '';
                if (data === 'browser') {
                    displayText = 'Tarayıcı';
                } else if (data === 'Notifications') {
                    displayText = 'Bildirimler';
                } else {
                    displayText = data;
                }

                return (
                    <div className='schedulednotifications-table-count'>
                        <span className='schedulednotifications-table-count-text'>{displayText}</span>
                    </div>
                );
            },
        },

        {
            title: GetWord('GÖNDERİLEN KİŞİ SAYISI'),
            dataIndex: 'count',
            key: 'count',
            render: (data, count) => {
                return (
                    <div className='schedulednotifications-table-count'>
                        <span className='schedulednotifications-table-count-text'>{data} Kişi</span>
                        <span className='schedulednotifications-table-count-text'>{count.successCount} Başarılı</span>
                        <span className='schedulednotifications-table-count-text'>{count.failureCount} Başarısız</span>
                    </div>
                );
            },
        },


        {
            title: <div className='schedulednotifications-table-date-title'>{GetWord('TARİH & SAAT')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, data) => <div className='schedulednotifications-table-date'>
                <span className='schedulednotifications-table-date-date'>{moment(date).format("LL")}</span>
                <span className='schedulednotifications-table-date-time'>{moment(date).format("LT")}</span>
            </div >,
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: GetWord('DURUM'),
            key: 'status',
            dataIndex: 'status',
            render: (_, { isSend }) => (
                <Tag color={isSend ? 'cyan' : 'orange'} key={GetWord}>
                    {GetWord(isSend ? 'Gönderildi' : 'Beklemede')}
                </Tag>
            ),
        },

        {
            title: GetWord('SEÇENEKLER'),
            key: 'options',
            render: (_, record) => (
                <Space size="middle">

                    <button className='schedulednotifications-table-options-edit' onClick={() => showNewNotificationModal(record)}>
                        <i className='icon-Edit notification-table-options-edit-icon' />
                    </button>
                    <button className='schedulednotifications-table-options-delete' onClick={() => showDeleteModal(record.id)} >
                        <i className='icon-Delete notification-table-options-delete-icon' />
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <div className='notification'>
            <div class="notification-header">
                <p className='schedulednotifications-header-title'>
                    {GetWord('Zamanlı Bildirimler')}
                </p>

                <div className='schedulednotifications-header-button'>
                    <button className='schedulednotifications-header-button-style' onClick={showNewNotificationModal}>
                        <i className='icon-Notification-Add notification-header-button-icon' />
                        <span className='schedulednotifications-header-button-text'> {GetWord('Yeni Bildirim')}</span>
                    </button>
                </div>
            </div>
            <div className='schedulednotifications-modal-public'>
                <Modal title={GetWord('Bildirim Oluştur')}
                    destroyOnClose={true}
                    open={isShowNewNotificationModalOpen} onOk={handleOk} onCancel={handleCancel} className={'schedulednotifications-modal ' + (selectedTab === "2" ? "notification-modal-selected2" : selectedTab === '3' ? "notification-modal-selected3" : "")}
                    // footer={[
                    //     <div className='schedulednotifications-modal-public-sendbutton'>
                    //         <Button key="submit" type="primary" onClick={handleOk} className='schedulednotifications-modal-public-sendbutton-style'>
                    //             <span className='schedulednotifications-modal-public-sendbutton-text'>
                    //                 Gönder</span>
                    //         </Button>
                    //     </div>,
                    // ]}
                    // style={{ width: selectedTab === "2" ? 1800 : 826 }}
                    // width={selectedTab === "2" ? 1800 : 826}
                    footer={false}
                >
                    <Notificationmodal defaultActiveKey='6' isVisableAllNotTimed={true} selectedData={selectedData} setSelectedTab={setSelectedTab} setIsRefresh={setIsRefresh} setModal={setShowNewNotificationModalOpen} />
                </Modal>
            </div>

            <div className='schedulednotifications-table'>
                <Table columns={columns} dataSource={currentPageData} pagination={false}
                />
            </div>

            <div className='schedulednotifications-deletemodal'>
                <Modal title={GetWord('Bu bildirimi siliyorsun. Emin misin?')} footer={null} closable={false} open={isDeleteModalOpen} onCancel={handleCancel} className='schedulednotifications-deletemodal'
                >
                    <Deletemodal setIsRefresh={setIsRefresh} handleCancel={handleCancel} id={selectedID} isSchedule={true} />
                </Modal>

            </div>

            <div className='schedulednotifications-notificationdetailmodal'>
                <Modal title={GetWord('Bildirim Detayı')} width={825} footer={null} open={isShowNotificationDetailModal} onCancel={handleCancel} className='schedulednotifications-notificationdetailmodal'
                >
                    <Notificationdetail />
                </Modal>

            </div>



            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')} </span>
                <Pagination
                    className="schedulednotifications-pagination"
                    current={currentPage}
                    pageSize={pageSize}
                    total={scheduleNotifications?.totalCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div>
        </div>
    )
}

export default ScheduledNotifications;
