import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker, TimePicker, Radio, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Service from '../../../../../../Service';

const { TextArea } = Input;

const NotificationDependingOnVehicle = ({ setIsRefresh, setModal }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [brands, setBrands] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [selectedBrandId, setSelectedBrandId] = useState(1);
    const [selectedFuelTypeId, setSelectedFuelTypeId] = useState(0);
    const [selectedTypeId, setSelectedTypeId] = useState(1);


    const handleBrandChange = (value) => {
        setSelectedBrandId(value || 1);
    };

    const handleFuelTypeChange = (value) => {
        setSelectedFuelTypeId(value || 0);
    };

    const handleTypeChange = (value) => {
        setSelectedTypeId(value || 1);
    };

    const getBrands = useCallback(() => {
        const service = new Service();
        service.GeneralService.getBrands().then((res) => {
            if (res) {
                setBrands([
                    { value: -1, label: 'Tümü' }
                    , ...res.map((item) => ({ value: item.carBrandId, label: item.name }))
                ]);
            }
        })
    }, []);

    const getFuelTypes = useCallback(() => {
        const service = new Service();
        service.GeneralService.getFuelTypes().then((res) => {
            if (res) {
                setFuelTypes(res.map((item) => ({ value: item.fuelTypeId, label: item.name + ` ${item.value}` })));
            }
        })
    }, []);

    const getVehicleTypes = useCallback(() => {
        const service = new Service();
        service.GeneralService.getVehicleTypes().then((res) => {
            if (res) {
                setVehicleTypes(res.map((item) => ({ value: item.vehicleTypeId, label: item.name })));
            }
        })
    }, []);

    useEffect(() => {
        getFuelTypes()
        getBrands()
        getVehicleTypes()
    }, [getBrands, getFuelTypes, getVehicleTypes, selectedBrandId, selectedFuelTypeId], selectedTypeId);

    const onFinish = useCallback((values) => {
        const query = `carBrandId=${values.carBrandId === -1 ? "" : values.carBrandId}&vehicleTypeId=${values.vehicleTypeId}&fuelTypeId=${values.fuelTypeId}`;

        const service = new Service();
        service.NotificationService.postVehicle(values, query).then((res) => {
            if (res) {
                setModal(false);
                setIsRefresh((prev) => !prev);
            }
        });
    }, [setIsRefresh, setModal]);

    dayjs.extend(customParseFormat);

    return (
        <div className='notification-modal-market'>
            <Form onFinish={onFinish} initialValues={{ navigationScreen: 'notifications', vehicleTypeId: 1, fuelTypeId: 0, carBrandId: -1 }}>
                <div className='notification-modal-market-content'>
                    {/* <span className='notification-modal-market-content-title'>
                        {GetWord('İçerik')}
                    </span> */}
                    <div className='notification-modal-market-content-form'>
                        <div className='notification-modal-market-content-form-titleimage'>
                            <Form.Item className='notification-modal-market-content-input' name='title'>
                                <Input placeholder={GetWord('Başlık')} />
                            </Form.Item>
                            <Form.Item className='notification-modal-market-content-url'
                                name="image"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('Görsel URL')} />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-market-content-form-content'>
                            <Form.Item className='notification-modal-market-content-textarea' name='body'>
                                <TextArea rows={4}
                                    placeholder={GetWord('İçerik')}
                                    style={{
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Ekran Yönlendir')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name='navigationScreen'>
                                <Select
                                    options={[
                                        { value: 'notifications', label: GetWord('Bildirimler') },
                                        { value: 'FuelPrices', label: GetWord('Yakıt Fiyatları') },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Tür')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name='vehicleTypeId'>
                                <Select
                                    options={vehicleTypes}
                                    onChange={handleTypeChange}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Araç Markası')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name='carBrandId'>
                                <Select
                                    options={brands}
                                    onChange={handleBrandChange}
                                />
                            </Form.Item>
                        </div>
                        {/* <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Araç Modeli')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'Fuel' },
                                        { value: '2', label: 'Diesel' },
                                        { value: '3', label: 'LPG' },
                                    ]}
                                />
                            </Form.Item>
                        </div> */}
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Yakıt Türü')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name='fuelTypeId'>
                                <Select
                                    options={fuelTypes}
                                    onChange={handleFuelTypeChange}

                                />
                            </Form.Item>
                        </div>
                        {/* <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Şehir')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'1'}
                                    options={[
                                        { value: '1', label: 'Fuel' },
                                        { value: '2', label: 'Diesel' },
                                        { value: '3', label: 'LPG' },
                                    ]}
                                />
                            </Form.Item>
                        </div> */}
                    </div>
                </div>

                {/* 
                <div className='notification-modal-campaign-datetime'>
                    <span className='notification-modal-campaign-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span>
                    <div className='notification-modal-campaign-datetime-datetimearea'>
                        <div className='notification-modal-campaign-datetime-datetimearea-datearea'>
                            <span className='notification-modal-campaign-datetime-datetimearea-datearea-title'>
                                {GetWord('Tarih')}
                            </span>

                            <Form.Item className='notification-modal-campaign-datetime-datetimearea-datearea-select'>
                                <DatePicker onChange={onChange}
                                    format={dateFormat}
                                    placeholder={today}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-campaign-datetime-datetimearea-timearea'>
                            <span className='notification-modal-campaign-datetime-datetimearea-timearea-title'>
                                {GetWord('Saat')}
                            </span>

                            <Form.Item className='notification-modal-campaign-datetime-datetimearea-timearea-select'>
                                <TimePicker onChange={onChange}
                                    format={timeformat}
                                    placeholder={currentTime}

                                />
                            </Form.Item>
                        </div>
                    </div>
                </div> */}
                <Form.Item className='notification-modal-campaign-content-sendbutton'>
                    <Button htmlType="submit" type="primary" className='notification-modal-campaign-content-sendbutton-style'>
                        <span className='notification-modal-campaign-content-sendbutton-text'>
                            {GetWord('Gönder')}</span>
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
};

export default NotificationDependingOnVehicle